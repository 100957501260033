.truncate-button {
    white-space: nowrap;       /* Keep text on a single line */
    overflow: hidden;          /* Hide overflow */
    text-overflow: ellipsis;   /* Add ellipsis (…) at the end if text is too long */
    max-width: 200px;          /* Adjust this to your preferred width */
}

.highlighted-div {
    background-color: #d0e4f5; /* Light blueish color for highlighting */
}

.truncate-button:hover {
    cursor: pointer;
}

/* Hide array and index levels marked by the 'hide-level' class */
.jsoneditor-tree .hide-level {
    display: none !important;
}

/* Hide the count of items displayed at object levels */
.jsoneditor-tree .jsoneditor-count {
    display: none !important;
}